import { stringEnumToArray } from '../utils/jsObjects/enumUtils';
import { Pagination } from './common';
import { FormatIds } from './reactIntl';
import { CustomMarker, FieldType } from './spotForm';

export enum CompanyDashboardViewType {
	analytics = 'analytics',
	spots = 'spots',
}

export enum AnalyticsSearchOption {
	last30Days = 'last30Days',
	last3Months = 'last3Months',
	last6Months = 'last6Months',
	last12Months = 'last12Months',
	last2Years = 'last2Years',
	last5Years = 'last5Years',
}

export const ViewTypeTitle: Record<CompanyDashboardViewType, FormatIds> = {
	[CompanyDashboardViewType.analytics]: 'CompanyDashboard.Header.Menu.Analytics.Title',
	[CompanyDashboardViewType.spots]: 'CompanyDashboard.Header.Menu.ActionRequired.Title',
};

export enum FilterID {
	ASSET_FOLDERS = 'assetFolders',
	ASSET = 'asset',
	REPORTER = 'reporter',
	HIGHEST_SEVERITY = 'highestSeverity',
	DUE_DATE_RANGE = 'dueDateRange',
}

export const FILTER_TRANSLATIONS: Record<FilterID, FormatIds> = {
	[FilterID.ASSET_FOLDERS]: 'CompanyDashboard.Filter.AssetFolders',
	[FilterID.ASSET]: 'App.Asset',
	[FilterID.REPORTER]: 'App.Reporter',
	[FilterID.HIGHEST_SEVERITY]: 'CompanyDashboard.Filter.SpotTemplateConfigurations',
	[FilterID.DUE_DATE_RANGE]: 'App.Due.Date',
};

export interface FilterResponse {
	data: {
		id: string;
		name: string;
		options: { text: string; value: string | number }[];
	}[];
}

export type SpotTrendDataType = Record<string, number | string>[];

export type ChartDataItemType = {
	text: string;
	count: number;
	color: string;
};

export type ChartDataType = ChartDataItemType[];

export type LegendDataType = {
	text: string;
	color: string;
}[];

export type CompanyDashboardDataType = {
	spotDistributionChartData: ChartDataType;
	spotTrendChartData: SpotTrendDataType;
	statusChartData: ChartDataType;
	severityChartData: ChartDataType;
	tilesData: DashboardTileValueType;
};

// type for the values of each tile
export type DashboardTileValueType = Record<string, number>;

// type for content of each tile
export type DashBoardTileData = {
	title: FormatIds;
	tooltipText?: FormatIds;
};

export enum DashboardTiles {
	OPEN_SPOTS = 'Open Spots',
	MAJOR_SEVERITY_SPOTS = 'Major Severity Spots',
	OVERDUE_SPOTS = 'Overdue Spots',
	DUE_SPOTS = 'Due Spots',
}

export interface CompanyDashboardSpotListData {
	id: string;
	projectID: string;
	thumbnailURL?: string;
	ticketID: number;
	topicTemplateID: number;
	attributes: {
		[key: string]: any;
	};
	clientCreatedAt: number;
	clientUpdatedAt: number;
	created: number;
	updated: number;
	createdBy: number;
	updatedBy: number;
}

export interface CompanyDashboardSpotListReference {
	id: string;
	name: string;
	topicFields: {
		id: string;
		name: string;
		options?: { text: string; value: string; color?: string; marker?: CustomMarker }[];
		type: FieldType;
	}[];
}

export interface CompanyDashboardSpotListMeta {
	pagination: Pagination;
	references: { projects: CompanyDashboardSpotListReference[] };
}

export interface CompanyDashboardSpotListResponse {
	data: CompanyDashboardSpotListData[];
	meta: CompanyDashboardSpotListMeta;
}

export type CompanyDashboardSpotType = {
	projectName: string;
	projectID: string;
	topicID: string;
	ticketID: number;
	thumbnailURL?: string;
	attributes: {
		[key: string]: string | number;
	};
};

export enum TimeSeriesType {
	daily = 1,
	weekly,
	monthly,
	quarterly,
	biannually,
}

export const timeSeriesDateRangeMap = {
	[AnalyticsSearchOption.last30Days]: TimeSeriesType.weekly,
	[AnalyticsSearchOption.last3Months]: TimeSeriesType.monthly,
	[AnalyticsSearchOption.last6Months]: TimeSeriesType.monthly,
	[AnalyticsSearchOption.last12Months]: TimeSeriesType.monthly,
	[AnalyticsSearchOption.last2Years]: TimeSeriesType.quarterly,
	[AnalyticsSearchOption.last5Years]: TimeSeriesType.biannually,
};

export interface FreeTextFilter {
	filterText: string;
	filterableLayerIDs: string[];
}

export type CommonCompanyDashboardRequest = {
	timezone: string;
	companyID: string;
	projectIDs: string[];
	folderIDs: string[];
	builtInFilters: {
		createdDateRange: number[];
		highestSeverity?: boolean;
		dueDateRange?: number[];
	};
	selectionFilters: {
		[key: string]: number[] | string[];
	};
	freeTextFilter?: FreeTextFilter;
};

export type CompanyDashboardFilterRequest = {
	aggregation?: {
		fieldID: string;
		timeSeries?: {
			fieldID: string;
			type: TimeSeriesType;
		};
	};
	page?: number;
	size?: number;
} & CommonCompanyDashboardRequest;

export type SpotCountResponse = {
	count: number;
	text: string;
	date?: number;
}[];

export enum CountSummaryKey {
	openStatus = 'openStatus',
	highestSeverity = 'highestSeverity',
	overdue = 'overdue',
	due = 'due',
}

export interface CountSummaryResponse {
	[CountSummaryKey.openStatus]: number;
	[CountSummaryKey.highestSeverity]: number;
	[CountSummaryKey.overdue]: number;
	[CountSummaryKey.due]: number;
}

export enum SpotCountFieldIDs {
	Status = 'Status',
	Category = 'Category',
	Severity = 'Severity',
}

// temporary workaround where we only show values with the specific english names
export enum TableColumnFieldNames {
	severity = 'Severity',
	category = 'Category',
	status = 'Status',
	dueDate = 'Due Date',
	assignee = 'Assignee',
}

export const FIXED_TABLE_COLUMNS = stringEnumToArray(TableColumnFieldNames);

export const FormattedTableColumnNames: Record<TableColumnFieldNames, FormatIds> = {
	[TableColumnFieldNames.severity]: 'App.Severity',
	[TableColumnFieldNames.category]: 'SpotField.Category',
	[TableColumnFieldNames.status]: 'App.Status',
	[TableColumnFieldNames.dueDate]: 'App.Due.Date',
	[TableColumnFieldNames.assignee]: 'SpotTable.Column.Assignee',
};

export enum DueDateFilterOption {
	dueIn7Days = 1,
	dueIn14Days,
	dueIn30Days,
	overdue,
}

export const DueDateFilterOptionsDaysMap: Partial<Record<DueDateFilterOption, number>> = {
	[DueDateFilterOption.dueIn7Days]: 7,
	[DueDateFilterOption.dueIn14Days]: 14,
	[DueDateFilterOption.dueIn30Days]: 30,
};

export const DueDateRangeFilterOptions: { value: DueDateFilterOption; text: FormatIds }[] = [
	{
		value: DueDateFilterOption.dueIn7Days,
		text: 'CompanyDashboard.Filter.DueDate.DueInDays',
	},
	{
		value: DueDateFilterOption.dueIn14Days,
		text: 'CompanyDashboard.Filter.DueDate.DueInDays',
	},
	{
		value: DueDateFilterOption.dueIn30Days,
		text: 'CompanyDashboard.Filter.DueDate.DueInDays',
	},
	{
		value: DueDateFilterOption.overdue,
		text: 'CompanyDashboard.Filter.DueDate.Overdue',
	},
];

export enum BannerType {
	noAsset = 1,
	noSpot,
	error,
}

export enum ChartTypes {
	pie = 'pie',
	bar = 'bar',
	line = 'line',
}
