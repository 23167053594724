import config from '../../config';
import { AssetCategory } from '../../tstypes';
import { AssetCategoryRoutes } from '../../tstypes/assets';
import { TemplateRouteActions } from '../../tstypes/checklistTemplate';
import { Folder } from '../../tstypes/common';
import { CompanyDashboardViewType } from '../../tstypes/companyDashboard';
import { AssetsViewType, UNSUPPORTED_MOBILE_VIEW_TYPES } from '../../tstypes/projects';
import { SpotTypes } from '../../tstypes/spot';
import {
	NewSpotTemplateRouteActionType,
	NewSpotTemplateRouteActions,
	SpotTemplateRouteActions,
} from '../../tstypes/spotTemplate';
import { getAssetViewType, getFolderID } from '../../utils/multiAssets/multiAssetsUtils';
import * as routeUtils from '../../utils/route/routeUtils';

export const BACK = '/back';

// /company/:companyID
export const getBasePath = (companyID: string = ':companyID') => `/company/${companyID}`;

// /company/:companyID/project
export const getBaseProjectsPath = (companyID: string = ':companyID') => `/company/${companyID}/projects`;

// /company/:companyID/project/:projectID
export const getProjectsPath = (companyID: string = ':companyID', projectID: string = ':projectID') =>
	`/company/${companyID}/projects/${projectID}`;

// basic Routes
export const TWO_D_VIEW = '/2dView';

export const THREE_D_VIEW = '/3dView';

export const TEAM = '/team';

export const REPORT_TEMPLATE = '/reportTemplate';

export const SPOT_TEMPLATE = '/spotTemplate';

export const MAP = 'map';

export const TOPICS = 'topics';

// region Routes level
export const HOME = '/home';

export const AUTH = '/auth';

export const APP_AUTH = '/appAuth';

export const LOGOUT = '/logout';

export const SIGN_UP = '/signUp';

export const RESET_PASSWORD = '/resetPassword';

export const LICENSE_ACTIVATION = '/licenseActivation';

export const LICENSE = '/license';

// ifc converter
export const VIEWER = '/viewer';

export const NOT_FOUND = '/notFound';

export const MOBILE_NOT_SUPPORTED = '/mobileNotSupported';

export const ACCESS_DENIED = '/accessDenied';

export const CRACK_VIEWER = '/crackViewer';

export const MAP_VIEWER = '/mapViewer';

export const ASSET_2D_VIEWER = '/2dViewer';

// spot overview & zoomed images
export const STOREY_VIEWER = '/storeyViewer';

// 3D view overview
export const ASSET_3D_VIEWER = '/3dViewer';

export const ATTACHMENT_VIEWER = '/attachmentViewer';

export const GENERIC_ATTACHMENT_VIEWER = '/genericAttachmentViewer';

export const STANDALONE_VIEWER_TESTER = '/standaloneViewerTester';

export const GALLERY_VIEWER = '/galleryViewer';

export const CHECKLIST_2D_VIEWER = '/checklist2dViewer';

export const CHECKLIST_3D_VIEWER = '/checklist3dViewer';

export const CHECKLIST_MAP_VIEWER = '/checklistMapViewer';

export const APP_USER_PROFILE = '/appProfile/:userID';

export const WEB_VIEW_APP_ROOT = '/webViewApp';

export const EMPTY_COMPANY = '/emptyCompany';

export const FILE_DOWNLOAD = '/fileDownload';

export const DRAWING_OVERVIEW = '/drawingOverview';

export const EXCEL_INTEGRATION = '/excelIntegration';

export const LINK_SHARING = '/s/:region/:linkID';

// /company/:companyID
export const COMPANY_WITH_ID = getBasePath();

export const COMPANY_DASHBOARD = `${COMPANY_WITH_ID}/dashboard`;

export const COMPANY_DASHBOARD_OVERVIEW = `${COMPANY_DASHBOARD}/:viewType(${Object.values(CompanyDashboardViewType).join('|')})`;

export const USER = `${COMPANY_WITH_ID}/user`;

export const USER_INVITE = `${COMPANY_WITH_ID}/user/invite`;

export const SETTINGS = `${COMPANY_WITH_ID}/settings`;

export const USER_PROFILE = `${COMPANY_WITH_ID}/profile`;

export const USER_PROFILE_SUB_PAGE = `${COMPANY_WITH_ID}/profile/:subPage?`;

export const VIDEO_TUTORIAL = `${COMPANY_WITH_ID}/videoTutorial`;

export const NOTIFICATIONS = `${COMPANY_WITH_ID}/notifications`;

// /company/:companyID/projects
export const PROJECTS = getBaseProjectsPath();

// /company/:companyID/projects/:viewType/:folderID
export const PROJECTS_OVERVIEW = `${PROJECTS}/:viewType(${Object.values(AssetsViewType).join('|')})/:folderID`;
export const UNSUPPORTED_MOBILE_PROJECTS_OVERVIEW = `${PROJECTS}/:viewType(${Array.from(
	UNSUPPORTED_MOBILE_VIEW_TYPES
).join('|')})/:folderID`;

// /company/:companyID/projects/:projectID
export const PROJECTS_WITH_ID = getProjectsPath();

// /company/:companyID/projects/:projectID/3dView
export const PROJECTS_3DVIEW = `${PROJECTS_WITH_ID}${THREE_D_VIEW}`;

// /company/:companyID/projects/:projectID/team
export const PROJECTS_TEAM = `${PROJECTS_WITH_ID}${TEAM}`;

// /company/:companyID/projects/:projectID/team/:userGroup
export const PROJECTS_TEAM_GROUP = `${PROJECTS_WITH_ID}${TEAM}/:userGroup`;

// /company/:companyID/projects/:projectID/documents
export const PROJECTS_DOCUMENTS_MAIN = `${PROJECTS_WITH_ID}/documents`;

// /company/:companyID/projects/:projectID/documents/folder/:folderID?
export const PROJECTS_DOCUMENTS_FOLDER = `${PROJECTS_WITH_ID}/documents/folder/:folderID?`;

// /company/:companyID/projects/:projectID/photos
export const PROJECTS_PHOTOS_MAIN = `${PROJECTS_WITH_ID}/photos`;

// /company/:companyID/projects/:projectID/photos/folder/:folderID?
export const PROJECTS_PHOTOS_FOLDER = `${PROJECTS_WITH_ID}/photos/folder/:folderID?`;

// /company/:companyID/projects/:projectID/reports
export const PROJECTS_REPORTS_MAIN = `${PROJECTS_WITH_ID}/reports`;

// /company/:companyID/projects/:projectID/reports/folder/:folderID?
export const PROJECTS_REPORTS_FOLDER = `${PROJECTS_WITH_ID}/reports/folder/:folderID?`;

// /company/:companyID/projects/:projectID/drawings
export const PROJECTS_DRAWINGS_MAIN = `${PROJECTS_WITH_ID}/drawings`;

// /company/:companyID/projects/:projectID/drawings/folder/:folderID?
export const PROJECTS_DRAWINGS_FOLDER = `${PROJECTS_WITH_ID}/drawings/folder/:folderID?`;

// /company/:companyID/projects/:projectID/2dView
export const PROJECTS_2DVIEW_MAIN = `${PROJECTS_WITH_ID}${TWO_D_VIEW}`;

// /company/:companyID/projects/:projectID/2dView/folder/:folderID?
export const PROJECTS_2DVIEW_FOLDER = `${PROJECTS_WITH_ID}${TWO_D_VIEW}/folder/:folderID?`;

// /company/:companyID/projects/:projectID/spotTemplate
export const PROJECTS_SPOT_TEMPLATE_MAIN = `${PROJECTS_WITH_ID}${SPOT_TEMPLATE}`;

// /company/:companyID/projects/:projectID/spotTemplate/(published|unpublished)
export const PROJECTS_SPOT_TEMPLATE = `${PROJECTS_WITH_ID}${SPOT_TEMPLATE}/:folderID(${Object.values(Folder).join(
	'|'
)})`;

// /company/:companyID/projects/:projectID/spotTemplate/(new|import|import_file)
export const PROJECTS_SPOT_TEMPLATE_NEW = `${PROJECTS_WITH_ID}${SPOT_TEMPLATE}/:action(${NewSpotTemplateRouteActions.join(
	'|'
)})`;

// /company/:companyID/projects/:projectID/spotTemplate/:templateID/:action(edit|duplicate|publish|delete)
export const PROJECTS_SPOT_TEMPLATE_ACTION = `${PROJECTS_WITH_ID}${SPOT_TEMPLATE}/:templateID/:action(${Object.values(
	SpotTemplateRouteActions
).join('|')})`;

// /company/:companyID/projects/:projectID/reportTemplate
export const PROJECTS_REPORT_TEMPLATE = `${PROJECTS_WITH_ID}${REPORT_TEMPLATE}`;

// /company/:companyID/projects/:projectID/checklistTemplate
export const PROJECTS_CHECKLIST_TEMPLATE_MAIN = `${PROJECTS_WITH_ID}/checklistTemplate`;

// /company/:companyID/projects/:projectID/checklistTemplate/(published|unpublished)
export const PROJECTS_CHECKLIST_TEMPLATE = `${PROJECTS_WITH_ID}/checklistTemplate/:folderID(${Object.values(
	Folder
).join('|')})`;

// /company/:companyID/projects/:projectID/checklistTemplate/new
export const PROJECTS_CHECKLIST_TEMPLATE_NEW = `${PROJECTS_WITH_ID}/checklistTemplate/new`;

// /company/:companyID/projects/:projectID/checklistTemplate/:templateID/:action(edit|duplicate|publish|delete|import)
export const PROJECTS_CHECKLIST_TEMPLATE_ACTION = `${PROJECTS_WITH_ID}/checklistTemplate/:templateID/:action(${Object.values(
	TemplateRouteActions
).join('|')})`;

// /company/:companyID/projects/:projectID/checklist
export const PROJECTS_CHECKLIST_MAIN = `${PROJECTS_WITH_ID}/checklists`;

// /company/:companyID/projects/:projectID/checklist/folder
export const PROJECTS_CHECKLIST_FOLDER_DEFAULT = `${PROJECTS_WITH_ID}/checklists/folder`;

// /company/:companyID/projects/:projectID/checklist/folder/:folderID
export const PROJECTS_CHECKLIST_FOLDER = `${PROJECTS_WITH_ID}/checklists/folder/:folderID`;

// /company/:companyID/projects/:projectID/checklist/:checklistID
export const PROJECTS_SINGLE_CHECKLIST = `${PROJECTS_WITH_ID}/checklists/:checklistID`;

export const PROJECTS_SINGLE_CHECKLIST_WITH_FOLDER = `${PROJECTS_SINGLE_CHECKLIST}/folder/:folderID`;

// /company/:companyID/projects/:projectID/topics
export const PROJECTS_SPOTS = `${PROJECTS_WITH_ID}/${TOPICS}`;

// /company/:companyID/projects/:projectID/map
export const PROJECTS_MAP = `${PROJECTS_WITH_ID}/${MAP}`;

// /company/:companyID/projects/:projectID/drawingOverview
export const PROJECTS_DRAWING_OVERVIEW = `${PROJECTS_WITH_ID}${DRAWING_OVERVIEW}`;

// /company/:companyID/projects/:projectID/viewer
export const PROJECTS_VIEWER = `${PROJECTS_WITH_ID}/viewer`;

// /company/:companyID/projects/:projectID/gallery
export const PROJECTS_GALLERY = `${PROJECTS_WITH_ID}/gallery`;

export const PROJECTS_GALLERY_2D_VIEW = `${PROJECTS_WITH_ID}/gallery${TWO_D_VIEW}`;

// /company/:companyID/projects/:projectID/dashboard
export const PROJECTS_DASHBOARD = `${PROJECTS_WITH_ID}/dashboard`;

export const STANDALONE_VIEWERS_LIST = [
	VIEWER,
	CRACK_VIEWER,
	MAP_VIEWER,
	ASSET_2D_VIEWER,
	STOREY_VIEWER,
	ASSET_3D_VIEWER,
	ATTACHMENT_VIEWER,
	GALLERY_VIEWER,
	CHECKLIST_2D_VIEWER,
	CHECKLIST_3D_VIEWER,
	CHECKLIST_MAP_VIEWER,
	GENERIC_ATTACHMENT_VIEWER,
	STANDALONE_VIEWER_TESTER,
];

export const APP_ROUTES = [APP_AUTH, WEB_VIEW_APP_ROOT, APP_USER_PROFILE];

// endregion

// region quick methods

export const getCompanyDashboardPath = (companyID: string, viewType: string) =>
	`${getBasePath(companyID)}/dashboard/${viewType}`;

// /company/:companyID/profile/:subpage
export const getUserProfilePath = (companyID: string, profilePage: string) =>
	`${getBasePath(companyID)}/profile/${profilePage}`;

// /project/:projectID
export const getQuickProjectsPath = (projectID: string) => `/projects/${projectID}`;

// /project/:projectID/dashboard
export const getDashboardPath = (projectID: string) => `${getQuickProjectsPath(projectID)}/dashboard`;

// /project/:projectID/topics
export const getTopicsPath = (
	projectID: string,
	params: { topicID?: string; chatMessageID?: string; reporter?: number }
) => routeUtils.makeQueryPath(`${getQuickProjectsPath(projectID)}/topics`, params);

// /project/:projectID/map
export const getMapPath = ({
	projectID,
	topicID,
	checklistID,
	itemID,
	redirectUrl,
	defaultCreateSpot,
	focusMode,
	spotType,
}: {
	checklistID?: string;
	itemID?: string;
	redirectUrl?: string;
	defaultCreateSpot?: string;
	focusMode?: string;
	projectID: string;
	topicID?: string;
	spotType?: SpotTypes;
}) =>
	routeUtils.makeQueryPath(`${getQuickProjectsPath(projectID)}/map`, {
		spotType,
		topicID,
		checklistID,
		itemID,
		redirectUrl,
		defaultCreateSpot,
		focusMode,
	});

// /project/:projectID/drawingOverview
export const getDrawingOverviewPath = ({
	projectID,
	assetID,
	topicID,
	checklistID,
	itemID,
	redirectUrl,
	defaultCreateSpot,
	focusMode,
}: {
	checklistID?: string;
	itemID?: string;
	redirectUrl?: string;
	defaultCreateSpot?: string;
	focusMode?: string;
	projectID: string;
	assetID?: string;
	topicID?: string;
}) =>
	routeUtils.makeQueryPath(`${getQuickProjectsPath(projectID)}${DRAWING_OVERVIEW}`, {
		assetID,
		topicID,
		checklistID,
		itemID,
		redirectUrl,
		defaultCreateSpot,
		focusMode,
	});

// /project/:projectID/2dView
export const get2DViewPath = (projectID: string, assetID?: string) =>
	routeUtils.makeQueryPath(`${getQuickProjectsPath(projectID)}${TWO_D_VIEW}`, { assetID });

// /project/:projectID/viewer
export const getViewerPath = (projectID: string, param: { topicID?: string; markerID?: string; modelID?: string }) =>
	routeUtils.makeQueryPath(`${getQuickProjectsPath(projectID)}/viewer`, param);

// /project/:projectID/model
export const getModelPath = (projectID: string, modelID?: string) =>
	routeUtils.makeQueryPath(`${getQuickProjectsPath(projectID)}${THREE_D_VIEW}`, { modelID });

// /project/:projectID/spotTemplate/folderID
export const getSpotTemplatePath = ({ projectID, folderID }: { projectID: string; folderID?: string }) =>
	`${getQuickProjectsPath(projectID)}${SPOT_TEMPLATE}/${folderID}`;

// /project/:projectID/spotTemplate/new
export const getSpotTemplateNewPath = (projectID: string, action: NewSpotTemplateRouteActionType) =>
	`${getQuickProjectsPath(projectID)}${SPOT_TEMPLATE}/${action}`;

// /project/:projectID/spotTemplate/:templateID/:action
export const getSpotTemplateActionPath = ({
	projectID,
	templateID,
	action,
}: {
	projectID: string;
	templateID: string;
	action: SpotTemplateRouteActions;
}): string => `${getQuickProjectsPath(projectID)}${SPOT_TEMPLATE}/${templateID}/${action}`;

// /project/:projectID/reports/folder/?assetID=${assetID}
export const getReportPath = (projectID: string, assetID?: string) =>
	routeUtils.makeQueryPath(`${getQuickProjectsPath(projectID)}/reports/folder/`, { assetID });

// /project/:projectID/${assetType}/folder/:folderID
export const getAssetComponentFolderPath = ({
	projectID,
	folderID = '',
	assetCategory,
}: {
	projectID: string;
	folderID?: string;
	assetCategory: AssetCategory;
}) => `${getQuickProjectsPath(projectID)}/${AssetCategoryRoutes[assetCategory]}/folder/${folderID}`;

// /project/:projectID/gallery
export const getGalleryPath = (projectID: string) => `${getQuickProjectsPath(projectID)}/gallery`;

// /project/:projectID/gallery/2dView?assetID=${assetID}&photoID=${photoID}&spotID={spotID}
export const getGallery2DViewPath = (
	projectID: string,
	param: { assetID?: string; topicID?: string; photoID?: string }
) => routeUtils.makeQueryPath(`${getQuickProjectsPath(projectID)}/gallery${TWO_D_VIEW}`, param);

// /project/:projectID/team/:userGroup
export const getTeamUserGroupPath = (projectID: string, userGroup: string | number) =>
	`${getQuickProjectsPath(projectID)}${TEAM}/${userGroup}`;

// /project/:projectID/topics
export const getProjectsOverview = (
	companyID: string,
	viewType?: string,
	folderID?: string,
	params: { searchText?: string; sort?: string; createAsset?: string } = {}
) =>
	routeUtils.makeQueryPath(
		`${getBaseProjectsPath(companyID)}/${getAssetViewType(viewType)}/${getFolderID(folderID)}`,
		params
	);

export const getCurrentCompanyFolderPath = (
	viewType?: string,
	folderID?: string,
	params: { searchText?: string; sort?: string } = {}
) => routeUtils.makeQueryPath(`/projects/${getAssetViewType(viewType)}/${getFolderID(folderID)}`, params);

// /project/:projectID/checklistTemplate/folderID
export const getChecklistTemplatePath = ({ projectID, folderID }: { projectID: string; folderID?: string }) =>
	`${getQuickProjectsPath(projectID)}/checklistTemplate/${folderID}`;

// /project/:projectID/checklistTemplate/new
export const getChecklistTemplateNewPath = (projectID: string) =>
	`${getQuickProjectsPath(projectID)}/checklistTemplate/new`;

// /project/:projectID/checklistTemplate/:templateID/:action
export const getChecklistTemplateActionPath = ({
	projectID,
	templateID,
	action,
}: {
	projectID: string;
	templateID: string;
	action: string;
}): string => `${getQuickProjectsPath(projectID)}/checklistTemplate/${templateID}/${action}`;

// /project/:projectID/checklist/folder/folderID
export const getchecklistFolderPath = ({ projectID, folderID = '' }: { projectID: string; folderID?: string }) =>
	`${getQuickProjectsPath(projectID)}/checklists/folder/${folderID}`;

export const getChecklistListPath = (projectID) => `${getQuickProjectsPath(projectID)}/checklists/folder`;

// /project/:projectID/checklist/:checklistID?templateID=${templateID}
export const getChecklistPath = ({
	projectID,
	checklistID,
	folderID,
	templateID,
	itemID,
}: {
	projectID: string;
	checklistID: string;
	folderID?: string;
	templateID: string;
	itemID?: string;
}): string =>
	`${getQuickProjectsPath(projectID)}/checklists/${checklistID}${
		folderID ? `/folder/${folderID}/` : ''
	}?templateID=${templateID}${itemID ? `&itemID=${itemID}` : ''}`;

// endregion

// region Eagle ID

export const EAGLE_ID_LOGIN_UI = `${config.EAGLE_ID_DOMAIN}/login`;

export const EAGLE_ID_SIGN_UP_UI = `${config.EAGLE_ID_DOMAIN}/signUp`;

export const EAGLE_ID_RESET_PASSWORD_UI = `${config.EAGLE_ID_DOMAIN}/resetPassword`;

export const EAGLE_ID_SWITCH_ACCOUNT = `${config.EAGLE_ID_DOMAIN}/switchAccount`;

export const EAGLE_ID_VERIFY_PASSWORD = `${config.EAGLE_ID_DOMAIN}/verifyPassword`;

export const EAGLE_ID_TWOFA = `${config.EAGLE_ID_DOMAIN}/2FA`;

export const EAGLE_ID_TWOFA_PROFILE = `${EAGLE_ID_TWOFA}/profile`;

export const EAGLE_ID_TWOFA_SETUP = `${EAGLE_ID_TWOFA}/setup/setUpAuthenticator`;

export const EAGLE_ID_TWOFA_RESET = `${EAGLE_ID_TWOFA}/reset`;

export const EAGLE_ID_TWOFA_RECOVERY_KEYS = `${EAGLE_ID_TWOFA}/recoveryKey`;

export const getEagleIDPersonalProfileUI = (userID: string) =>
	`${config.EAGLE_ID_DOMAIN}/user/${userID}/personalProfile`;

export const getEagleIDCompanyProfileUI = (userID: string) => `${config.EAGLE_ID_DOMAIN}/user/${userID}/companyProfile`;

export const getEagleIDLicenseUI = (userID: string) =>
	`${config.EAGLE_ID_DOMAIN}/user/${userID}/license/subscriptionInfo`;

export const getEagleIDUpgradeUI = (userID: string) => `${config.EAGLE_ID_DOMAIN}/user/${userID}/license/upgradeInfo`;

export const getEagleIDContactUI = (userID: string) => `${config.EAGLE_ID_DOMAIN}/user/${userID}/license/contactSales`;

export const getEagleIDMultipleLoginURL = () => `${config.EAGLE_ID_DOMAIN}/multipleDevices/login`;

// endregion Eagle ID
